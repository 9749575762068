import { TextField, Button, Card, Box, CardContent } from "@mui/material";
import PageHeader from "../Components/PageHeading";
import PageLayout from "../Components/PageLayout"
import { generatePath    } from 'react-router-dom'
import { ReactElement, useState } from "react";
import axios from "axios";
import BaseUrl from "../constants/baseUrl";
import { DataGrid, GridColumns, GridToolbar } from "@mui/x-data-grid";
import urls from "../constants/urls";
import Loading, { ApiResponse, LoadingOutput } from "../Components/Loading";
import { nameof } from "../utils/nameof";

interface UserSearchItem {
    id:string,
    Firstname: string,
    Lastname: string
    email: string,
    partnerid: string,
    activationdate: string,
    cancelledDate: string
} 

const UsersHome = () => {
    const [searchFieldValue, setSearchFieldValue] = useState(sessionStorage.getItem('usersearchstring')?? '')
    const [lastSearch, setLastSearch] = useState({ email: '' })

    async function RunSearch(): Promise<ApiResponse<UserSearchItem>> {
       
        if (lastSearch.email === '') {
            console.log("Not Loading Data for search");
            return { success: false, data: [] };
        }

        console.log("Loading Data for search");
        const options = {
            method: "POST",
            url: `${BaseUrl}/api/users/search`,
            headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
            data: lastSearch

        };
        const response = await axios.request(options);
        return response.data;

    }

    const doSearch = () => {sessionStorage.setItem('usersearchstring',searchFieldValue);setLastSearch({ email: searchFieldValue })}
    return (
        <PageLayout>
            <PageHeader>USERS</PageHeader>
            Email: <TextField value={searchFieldValue} variant="outlined" onChange={(e) => setSearchFieldValue(e.target.value)} onKeyDown={(e) => (e.keyCode === 13 ? doSearch() : null)} label="Email" />
            <Button onClick={doSearch}>Search</Button>

            {lastSearch.email !== '' && <p>Search results for: {lastSearch.email}</p>}
            <Loading Component={SearchResultsDisplay} LoadingFunction={RunSearch} />

        </PageLayout>
    );

}



function SearchResultsDisplay(props: LoadingOutput<UserSearchItem>) : ReactElement{
    const columns: GridColumns = [
        {
            field: nameof<UserSearchItem>("Firstname"),
            headerName: "First Name",
            width: 200,
        },
        {
            field: nameof<UserSearchItem>("Lastname"),
            headerName: "Last Name",
            width: 200
        },
        {
            field: nameof<UserSearchItem>("email"),
            headerName: "Email",
            width: 350
        },
        {
            field: nameof<UserSearchItem>("partnerid"),
            headerName: "partnerId",
            width: 200
        },
        {
            field: nameof<UserSearchItem>("activationdate"),
            headerName: "Activation Date",
            width: 150
        }, {
            field: nameof<UserSearchItem>("cancelledDate"),
            headerName: "Cancellation Date",
            width: 150
        },
        {
            field: "md",
            headerName: "More Details",
            renderCell: (params) => {
                return (<Button 
                    //onClick={() =>
                    //navigate(generatePath(urls.UserDetails, { id: params.row.id })
                    href={generatePath(urls.UserDetails, { id: params.row.id })
                }>More Details</Button>)
            }
        }
    ]
    if (props.data.length === 0)
        return <></>
    return <Card>
        <CardContent>
            <Box display="flex">
                <Box sx={{ width: "100%" }}>
                    <DataGrid
                        components={{ Toolbar: GridToolbar }}
                        rows={props.data}
                        columns={columns}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                        checkboxSelection
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        density="compact"
                        autoHeight={true}
                    />
                </Box>
            </Box>
        </CardContent>
    </Card>
}

export default UsersHome;