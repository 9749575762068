import { useEffect, useState } from "react";
import PageHeader from "../Components/PageHeading";
import PageLayout from "../Components/PageLayout"
import BaseUrl from "../constants/baseUrl";
import axios from "axios";
import { DataGrid, GridColumns, GridToolbar, GridValueGetterParams } from "@mui/x-data-grid";
import { nameof } from "../utils/nameof";
import {
    Box,
    Card,
    CardContent,
    Typography
  } from "@mui/material";


interface SignupMetricsRow {
    partnerId: string,
    partnerName: string,
    lastWeek: number,
    thisWeek: number, 
    fortnightTotal: number
}
interface MonthlyInteractionsRow {
    year: number,
    month: number,
    questions: number,
    vetInteractions: number, 
    customerInteractions: number,
    totalInteractions: number,
    vetInteractionsPerQuestion: number
}

interface DTO {
    signupMetrics:  SignupMetricsRow[]
    monthlyInteractionStats: MonthlyInteractionsRow[]
}
const Metrics = () => {

    const [loadingStatus, setLoadingStatus] = useState({ status: "notLoading" });

    const [getData, setData] = useState<DTO>();
    useEffect(() => {
      async function loadData() {
        console.log("Loading Data for Metrics");
        var options = {
          method: "GET",
          url: `${BaseUrl}/api/metrics`,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        };
        const response = await axios.request(options);
  
        setData(response.data);
        setLoadingStatus({ status: "loaded" });
      }
      if (loadingStatus.status === "notLoading") {
        setLoadingStatus({ status: "loading" });
        loadData();
      }
    }, [loadingStatus]);

    console.log(getData)

    const signupColumns : GridColumns = [
        {
            field: nameof<SignupMetricsRow>("partnerName"),
            headerName: "Partner",
            width: 250,
            valueGetter: (params : GridValueGetterParams<SignupMetricsRow>) => `${params.row.partnerName === null ? params.row.partnerId : params.row.partnerName}`
        },
        {
            field: nameof<SignupMetricsRow>("lastWeek"),
            headerName: "Signups Last Week", 
            width: 200
        },
        {
            field: nameof<SignupMetricsRow>("thisWeek"),
            headerName: "Signups This Week",
            width: 200
        },
        {
            field: nameof<SignupMetricsRow>("fortnightTotal"),
            headerName: "2 Week Total",
            width: 200
        },
    ]

    const monthlyInteractionsColumns : GridColumns = [
        {
            field: nameof<MonthlyInteractionsRow>("year"),
            headerName: "Year",
            width: 150
        },
        {
            field: nameof<MonthlyInteractionsRow>("month"),
            headerName: "Month", 
            width: 150
        },
        {
            field: nameof<MonthlyInteractionsRow>("questions"),
            headerName: "Questions",
            width: 150
        },
        {
            field: nameof<MonthlyInteractionsRow>("vetInteractions"),
            headerName: "Vet Interactions",
            width: 150
        },
        {
            field: nameof<MonthlyInteractionsRow>("customerInteractions"),
            headerName: "Customer Interactions",
            width: 150
        },
        {
            field: nameof<MonthlyInteractionsRow>("totalInteractions"),
            headerName: "Total Interactions",
            width: 150
        },
        {
            field: nameof<MonthlyInteractionsRow>("vetInteractionsPerQuestion"),
            headerName: "Vet Interactions Per Question",
            width: 250
        },
    ]
    if (getData && loadingStatus.status === "loaded") {
    const monthlyInteractionStats = getData.monthlyInteractionStats
    const signupData = getData.signupMetrics
    return (
        <PageLayout>
            <PageHeader>METRICS</PageHeader>
            




            <Card>
              <CardContent>
                <Typography variant="h6" fontWeight={"600"}>
                  Signups
                </Typography>
                <Box display="flex">
                  <Box sx={{ height: 400, width: "100%" }}>
                   <DataGrid
                      //getRowId={(row) => { console.log('id:', row.partnerId); return row.partnerId}}
                      getRowId={() => Math.floor(Math.random() * 100000000)}
                      components={{ Toolbar: GridToolbar }}
                      rows={signupData}
                      columns={signupColumns}
                      pageSize={50}
                      rowsPerPageOptions={[50]}
                      checkboxSelection
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                      density="compact"
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <Typography variant="h6" fontWeight={"600"}>
                  Monthly Interactions Metrics
                </Typography>
                <Box display="flex">
                  <Box sx={{ height: 400, width: "100%" }}>
                   <DataGrid
                      //getRowId={(row) => { console.log('id:', row.partnerId); return row.partnerId}}
                      getRowId={() => Math.floor(Math.random() * 100000000)}
                      components={{ Toolbar: GridToolbar }}
                      rows={monthlyInteractionStats}
                      columns={monthlyInteractionsColumns}
                      pageSize={50}
                      rowsPerPageOptions={[50]}
                      checkboxSelection
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                      density="compact"
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>


        </PageLayout>

    );
    }
    else {
        return null;
    }
}



export default Metrics