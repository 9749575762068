import { useRef, useState } from "react";
import PageHeader from "../Components/PageHeading";
import PageLayout from "../Components/PageLayout"
import { useParams } from 'react-router-dom'
import Loading, { ApiResponse } from "../Components/Loading";
import BaseUrl from "../constants/baseUrl";
import axios from "axios";
import BackButton from "../Components/BackButton";
import { Button, Card, CardContent, CardHeader, Modal, TextField, Box, Typography,  Tooltip as MUITooltip } from "@mui/material";
import { DataGrid, GridColumns, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";
import { PersonPinTwoTone, MedicalServicesTwoTone, ExpandMoreTwoTone, ExpandLessTwoTone  } from "@mui/icons-material";

const tooltipRenderer = (params : GridRenderCellParams) =>  (
    <MUITooltip title={params.value} >
     <span className="table-cell-trucate">{params.value}</span>
     </MUITooltip>
   )

const UserDetails = () => {
    const { id } = useParams()
    const getData = async (): Promise<ApiResponse<any>> => {
        if (!id || id === '') {
            return { success: false, data: [] };
        }
        const options = {
            method: "POST",
            url: `${BaseUrl}/api/users/details/`,
            headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
            data: { id }

        };
        console.log('options', options)
        return await axios.request(options);
    }
    return (
        <PageLayout>
            <BackButton url='Users' />
            <PageHeader>USER DETAILS</PageHeader>
            <Loading Component={UserDetailsDisplay} LoadingFunction={getData} />
        </PageLayout>
    );

}
const UserDetailsDisplay = (props: any) => {
    const petsColumns: GridColumns = [
        {
             field: 'name',
             headerName: "Name",
             width: 250,
         },
         {
             field: 'gender',
             headerName: "Gender",
             width: 150
         },
         {
             field: 'petType',
             headerName: "Species",
             width: 250
         },
         
         {
            field: 'status',
            headerName: "Status",
            width: 100
        },
     ]


     const logsColumns : GridColumns = [
        {
             field: 'date',
             headerName: "Date",
             width: 250,
             renderCell: (params : GridRenderCellParams) => {const d = new Date(Date.parse(params.value)); return `${d.toDateString()} ${d.toLocaleTimeString()}`}
            },
         {
             field: 'description',
             headerName: "Description",
             width: 250,
             renderCell: tooltipRenderer
         },
         {
             field: 'ip',
             headerName: "ip",
             width: 250
         },
         
         {
            field: 'user_agent',
            headerName: "User Agent",
            width: 100
        },{
            field: 'extra',
            headerName: "Other details",
            width: 300,
            renderCell: (params : GridRenderCellParams) => {return <MUITooltip title={"Click to View"} ><p onClick={(() => alert(params.value))}>{params.value}</p></MUITooltip>}

        },
     ]

    const data = props.data.data;
    const sortedConversations = data.conversations
    return <>
        <h2>{data.user.Firstname} {data.user.Lastname} ({data.user.email})</h2>
        <Card sx={{padding:"12px", marginBottom: '12px'}} raised>
            <CardHeader title="User Details"/>
            <CardContent>
                <div style={{display: 'flex', gap: '10px'}}>
                    <TextField
                    id="outlined-helperText"
                    label="First Name"
                    value={data.user.Firstname}
                    InputProps={{
                        readOnly: true,
                      }}
                />
                 <TextField
                    id="outlined-helperText"
                    label="Last Name"
                   value={data.user.Lastname}
                   InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                    id="outlined-helperText"
                    label="Email"
                   value={data.user.email}
                   InputProps={{
                    readOnly: true,
                  }}
                  sx={{width: '300px'}}
                />
                </div>
                <div style={{marginTop: '10px', display: 'flex', gap: '10px'}}>
                <TextField
                    id="outlined-helperText"
                    label="Partner Id"
                    value={data.user.partnerid ?? 'D2C'}
                    InputProps={{
                        readOnly: true,
                      }}
                />
                 <TextField
                    id="outlined-helperText"
                    label="Customer Type"
                    value={data.user.customertype}
                    InputProps={{
                        readOnly: true,
                      }}
                />
                 <TextField
                    id="outlined-helperText"
                    label="Lifecycle Stage"
                    value={data.user.lifecyclestage}
                    InputProps={{
                        readOnly: true,
                      }}
                />
                <TextField
                    id="outlined-helperText"
                    label="Activation Date"
                    value={data.user.activationdate ? new Date(data.user.activationdate).toLocaleDateString(): ''}
                    InputProps={{
                        readOnly: true,
                      }}
                />
                {props.data.data.user.cancelledDate && <TextField
                    id="outlined-helperText"
                    label="Cancellation Date"
                    value={data.user.cancelledDate}
                    InputProps={{
                        readOnly: true,
                      }}
                /> }
                </div>
            </CardContent>
        </Card>
        
        <Card sx={{padding:"12px", marginBottom: '12px'}} raised>
            <CardHeader title="Conversations"/>
            <CardContent>
                <div>
                    {sortedConversations && Object.keys(sortedConversations).map((key : any) => <ConversationDisplay key={key} pets={data.pets} conversationId={key} conversation={data.conversations[key]} />)}
                </div>
            </CardContent>
        </Card>
        <Card sx={{padding:"12px", marginBottom: '12px'}} raised>
            <CardHeader title="Pets" />
        <CardContent>
            <Box display="flex">
                <Box sx={{ height: 400, width: "100%" }}>
                    <DataGrid
                        components={{ Toolbar: GridToolbar }}
                        rows={data.pets}
                        columns={petsColumns}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        // checkboxSelection
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        // density="compact"
                    />
                </Box>
            </Box>
        </CardContent>
    </Card>
    <Card sx={{padding:"12px", marginBottom: '12px'}} raised>
            <CardHeader title="Authentication Logs" />
        <CardContent>
            <Box display="flex">
                
                <Box sx={{width: "100%" }}>
                    <Typography>(Max. last 200)</Typography>
                    <DataGrid
                        components={{ Toolbar: GridToolbar }}
                        rows={data.logs}
                        getRowId={row => {return row.log_id}}
                        columns={logsColumns}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        // checkboxSelection
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        // density="compact"
                        autoHeight={true}
                    />
                </Box>
            </Box>
        </CardContent>
    </Card>
    </>
}
const tableCellStyle = {
    verticalAlign:'top',
    padding:'9px'
    }
    
const convertDynamoDbTimeToHumanReadable = (timeStamp : Date) => {

    const date = new Date(timeStamp);
    const dateFormat: Intl.DateTimeFormatOptions = { 
        day: '2-digit', 
        month: 'long', 
        year: 'numeric'
        };
        const timeFormat: Intl.DateTimeFormatOptions = {
        hour: '2-digit', 
        minute: '2-digit', 
         
        timeZone: 'Europe/London',
        hour12: false 
        };
    const formattedDate = date.toLocaleString('en-GB', dateFormat).replace(/\s+/g, '-');
    const formattedTime = date.toLocaleString('en-GB', timeFormat);
    return  formattedDate+" "+formattedTime;
}
const ConversationDisplay = (props:any)  => {
    const conversation = props.conversation
    const pets = props.pets
    const [expanded, setExpanded] = useState(false)
    const toggleExpand = () => {setExpanded(!expanded)}
    
    const getPetDetail = (petId : any)  => {
        const pet = pets.find((pet: any) => pet.id === petId)
        return pet ? `${pet.name} (${pet.petType} - ${pet.species} - ${pet.gender})` : `Unknown Pet `
        }

    const conversationDate = new Date(conversation[0].firstAskedAt);
    const conversationDateDisplay =  (<><b>{conversationDate.toDateString()}</b>  {conversationDate.toLocaleTimeString()}</>)
    const conversationTitleStyle = {
        backgroundColor: '#ff5622',
        padding: '6px',
        marginTop: '3px',
        fontSize: '1.1em',
        color: 'white',
    }


    const handoverNoteEmailSearchString = "https://groups.google.com/a/vidivet.com/g/handover/search?q=%22" + encodeURIComponent(convertDynamoDbTimeToHumanReadable(conversationDate))+ "%22"
    const vetAppLink = `${BaseUrl}/link/VetApp/?id=${encodeURIComponent(conversation[0].id)}`
    return (
        <div>
            <div style={conversationTitleStyle} onClick={toggleExpand}>
                {conversationDateDisplay} - {getPetDetail(conversation[0].petId)} -   {conversation[conversation.length-1].outcome} ({conversation.length} interactions ) 
                <Button style={{float: 'right', color: 'white'}} onClick={toggleExpand}>{expanded ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />}</Button>
                <Button style={{float: 'right', color: 'white'}} href={handoverNoteEmailSearchString} target="_blank" rel="noreferrer"><Typography style={{color: 'white'}}>Handover Note Search</Typography> </Button>
                <Button style={{float: 'right', color: 'white'}} href={vetAppLink} target="_blank" rel="noreferrer"><Typography style={{color: 'white'}}>Vet App</Typography> </Button>
            </div>
            {expanded && <table style={{width: '100%'}}>
                <thead>
                    <tr>
                        <th style={{...tableCellStyle,width: '150px'}}></th>
                        <th style={{...tableCellStyle,width: '120px'}}></th>
                        <th> </th>
                    </tr>
                </thead>       
                <tbody>
                    {conversation.map((interaction:any, index: number) => <InteractionDisplay interaction={interaction} lastDate={conversation[index-1]?.createdAt} key={interaction.interaction_id}/>)}
                </tbody>
            </table>}
        </div>
    )
}
 
const vetRowStyle = {
    backgroundColor:'#fce1ca'
}

const InteractionDisplay = (props:any)=> {
    const interaction = props.interaction
    const lastDate = props.lastDate ? new Date(props.lastDate) : new Date(1,1,1)
    const thisDate = new Date(interaction.createdAt)
    let displayDate = (<><b>{thisDate.toDateString()}</b><br />{thisDate.toLocaleTimeString()}</>);

    //if it is the same day as the previous line just output the time
    if(lastDate.toDateString() === thisDate.toDateString()) {
        displayDate = (<>{thisDate.toLocaleTimeString()}</>);
    }

    let userDetails = null;
    let isVetResponse = false;
    if (interaction.isVetInteraction.data[0] !== 0) {
        userDetails = (<><MedicalServicesTwoTone sx={{fontSize: '50px'}}/><br />{interaction.vetName}</>)
        isVetResponse=true
    }
    else {
        userDetails = (<><PersonPinTwoTone sx={{fontSize: '50px'}}/><br />{interaction.name}</>)
    }
    return (
        <tr style={isVetResponse ? vetRowStyle : undefined}>    
            <td style={{...tableCellStyle, textAlign:"right"}}>
                <div>{displayDate}</div>
            </td>
            <td style={{...tableCellStyle, textAlign: 'center'}}>
                <div>{userDetails}</div>
                </td>
            <td style={{...tableCellStyle}}>
                {interaction.text}
                <MediaDisplay interaction={interaction} />
            </td>
        </tr>
    )
}


const MediaDisplay = (props:any) => {
    const interaction = props.interaction

    const [videoExpanded, setVideoExpanded] = useState(true)
    const videoRef = useRef<HTMLVideoElement>(null);
    
    const mediaSlots = [0,1,2,3,4]
    const mediaSlotsWithData = mediaSlots.map(slotIndex => {
        const mediaType = interaction[`media${slotIndex}_type`]
        const mediaUrl = interaction[`media${slotIndex}_url`]
        let hasData = false;
        if (mediaType) {
            hasData = true;
        }
        return {slotIndex, hasData, mediaType, mediaUrl}

    }).filter(x=> x.hasData)

    const expandContract = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
        const newState = !videoExpanded
        setVideoExpanded(newState)
    }

    if (videoExpanded) {
       
        if (mediaSlotsWithData.length === 0) {
            return (<div></div>)
        }
        if(mediaSlotsWithData.length === 1 && mediaSlotsWithData[0].mediaType === 'video') {
            return (
                <div>
                    <Button onClick={expandContract}>Collapse</Button>
                    <br />
                    <video ref={videoRef} controls width={350}>
                        <source src={mediaSlotsWithData[0].mediaUrl} />
                    </video>
                </div>)
        }
        else {
            return (
            <div>
                <Button onClick={expandContract}>Collapse</Button>
                <div style={{display: 'flex'}} >
                    {mediaSlotsWithData.map(mediaSlot => <MediaItemDisplay key={mediaSlot.mediaUrl} mediaInfo={mediaSlot} />)}
                </div>
            </div>)
        }
    }
    else {
        return (<div><Button onClick={expandContract}>Expand</Button><br />Media Summary </div>)
    }

}

const MediaItemDisplay = (props: any) => {
    const mediaInfo = props.mediaInfo
    const [mediaExpanded, setMediaExpanded] = useState(false)
    const expand = () => {
        setMediaExpanded(true);
    }
    const close = () => {
        setMediaExpanded(false);
    }

    const modalstyle = {
        position: 'absolute' as 'absolute',
        top: '10%',
        left: '10%',
        width: '80%',
        height: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    if (mediaInfo.mediaType === 'video') {
        if (!mediaExpanded) {
            return (<div key={mediaInfo.mediaUrl} className="ImageThumb"><Button onClick={expand}><img width="150" src={mediaInfo.mediaUrl + '.jpg'} alt=""/></Button></div>)
        }
        else {
            return ( <Modal
                key={mediaInfo.mediaUrl}
                open={mediaExpanded}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalstyle}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    <Button onClick={close}>Close</Button>
                  </Typography>
                  <video controls style={{maxHeight: '90%', maxWidth: '90%'}}>
                    <source  src={mediaInfo.mediaUrl} />
                  </video>
                </Box>
              </Modal>)
        }
    }
    if (mediaInfo.mediaType === "image") {
        if (!mediaExpanded) {
            return (<div key={mediaInfo.mediaUrl} className="ImageThumb"><Button onClick={expand}><img width="150" src={mediaInfo.mediaUrl} alt="" /></Button></div>)
        }
        else {
            return ( <Modal
                key={mediaInfo.mediaUrl}
                open={mediaExpanded}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalstyle}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    <Button onClick={close}>Close</Button>
                  </Typography>
                  <img src={mediaInfo.mediaUrl} style={{maxHeight: '90%', maxWidth: '90%'}} alt="" />
                </Box>
              </Modal>)
        }
    }
    return <></>
}

export default UserDetails;