import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Card,
  CardContent,
  Button,
  Tooltip as MUITooltip
} from "@mui/material";
import { DataGrid, GridRenderCellParams, GridToolbar, GridValueGetterParams, GridColumns} from "@mui/x-data-grid";
import BaseUrl from "../constants/baseUrl";
import axios from "axios";
import { useEffect, useState } from "react";
import { LineChart, BarChart, Line, Bar} from "recharts";
import PageLayout from "../Components/PageLayout";
import PreviewPartnerReport from "../Components/PreviewPartnerReport";
import PageHeading from "../Components/PageHeading";
import DashboardChart from "../Components/DashboardChart"


const tooltipRenderer = (params : GridRenderCellParams) =>  (
  <MUITooltip title={params.value} >
   <span className="table-cell-trucate">{params.value}</span>
   </MUITooltip>
 )
type ChartData = {
  partners : {partnerCode:string, partnerName:string}[],
  report: any,
  userchart:any,
  firstinteractionschart: any,
  firstinteractionshourschart : any,
  customerinteractionschart: any,
  customerinteractionshourschart : any
}

const Dashboard = () => {
  const [loadingStatus, setLoadingStatus] = useState({ status: "notLoading" });
  const [partner, setPartner] = useState("");
  const [chartData, setChartData] = useState<ChartData>();
  useEffect(() => {
    async function getData() {
      console.log("getting data");
      var options = {
        method: "GET",
        url: `${BaseUrl}/api/dashboard?`,
        params: { partner: partner },
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const response = await axios.request(options);

      setChartData(response.data);
      setLoadingStatus({ status: "loaded" });
    }
    if (loadingStatus.status === "notLoading") {
      setLoadingStatus({ status: "loading" });
      getData();
    }
  }, [loadingStatus, partner]);

  const columns : GridColumns = [
    {
      field: "id",
      headerName: "Open",
      width: 90,
      renderCell: (params : GridRenderCellParams) => {
        //return <Button href={'vetapp://details/' + params.value}>Open in App</Button>
        return <Button href={`${BaseUrl}/link/VetApp/?id=${params.value}`}>Open in App</Button>
      }
    },
    {
      field: "partnerId",
      headerName: "Partner Code",
      width: 150,      
      renderCell: tooltipRenderer,
    },
    {
      field: "firstAsked",
      headerName: "Conversation Date",
      width: 180,
      valueFormatter: ({value}) => {
        const d = new Date(value);
        return d.toLocaleString('en-gb')
      }
      
    },

    {
      field: "duration",
      headerName: "Duration",
      width: 80,
      valueGetter: (params : GridValueGetterParams) => new Date(new Date(params.row.mostRecentInteraction).getTime() - new Date(params.row.firstAsked).getTime()),
      valueFormatter: ({value}) => {
          const d = value;
          const hours = (((d.getUTCDate() -1) * 24 + d.getUTCHours()) + '').padStart(2, '0');
          const minutes = (d.getUTCMinutes() + '').padStart(2, '0')
          return `${hours}:${minutes}`
      }
     },
    {
        field: "totalInteractions",
        headerName: "Interactions",
        width: 100
       },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      //renderCell: tooltipRenderer,
      renderCell: (params : GridRenderCellParams) => {
        return <a href={`/users/${params.value}`}>{params.value}</a>
      }
    },
    {
      field: "custName",
      headerName: "Name",
      width: 180,
      valueGetter: (params : GridValueGetterParams) => `${params.row.firstname || ''} ${params.row.lastname || ''}`
    },
  
    {
      field: "petName",
      headerName: "Pet Name",
      width: 100,
    }, 
    {
      field: "respondingVets",
      headerName: "Responding Vets",
      width: 200,
      valueFormatter: ({value}) => {
        return value//.split(',').map.join(', ')
      },
      renderCell: tooltipRenderer,

    },
    {
      field: "outcome",
      headerName: "Outcome",
      width: 250,
      renderCell: tooltipRenderer,
    },
    {
      field: "bodySystem",
      headerName: "Body System",
      width: 250,
      renderCell: tooltipRenderer,
    },
    {
      field: "advice",
      headerName: "Advice",
      width: 250,
      renderCell: tooltipRenderer,
    },
    {
      field: "diagnostic",
      headerName: "Diagnostic",
      width: 250,
      renderCell: tooltipRenderer,
    },
    {
      field: "link",
      headerName: "Link",
      width: 250,
      renderCell: tooltipRenderer,
    },
  ];

  const rows = chartData?.report.map ( (x: any) => {return {...x, link: `${BaseUrl}/link/VetApp/?id=${x.id}` }});

const UnixTimeChartFormatter = (unixTime:number) => new Date(unixTime * 1000).toLocaleDateString()
const TimeTooltipLabelFormatter = (label: number) => new Date(label * 1000).toLocaleDateString()
const StringTimeLabelTooltipLabelFormatter = (label:string) => label + ":00"
  return (
    <>
      <PageLayout>
        {loadingStatus.status === "loaded" ? (
          <>
            <PageHeading>DASHBOARD</PageHeading>
           
            <Box display="flex" alignItems={"center"}>
                  <Typography>Choose Partner:</Typography>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={partner}
                      label="Partner"
                      onChange={(e) => {
                        setPartner(e.target.value);
                        setLoadingStatus({ status: "notLoading" });
                      }}
                    >
                       <MenuItem key={-1} value={''}> All (inc. D2C)</MenuItem>
                       <MenuItem key={-2} value={'D2C'}> Direct To Consumer</MenuItem>
                       <MenuItem key={-3} value={'PDSA-'}> All PDSA Partners</MenuItem>
                      {chartData?.partners.map((ele, i) => (
                        <MenuItem key={i} value={ele.partnerCode}>
                          {ele.partnerName} ({ele.partnerCode})
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
            {/* <GeneratePartnerReport /> */}

            <PreviewPartnerReport partner={partner} />

            <DashboardChart Data={chartData?.userchart} 
                            DataKey="val" 
                            XAxisProps={{
                                          dataKey: "name", 
                                          tickFormatter: UnixTimeChartFormatter
                                        }} 
                            DataSeriesProps={{stroke:"#8884d8"}} 
                            Title="Users Enrolled" 
                            ChartType={LineChart} 
                            SeriesType={Line} 
                            DataFrequencyTypeLabel="Users"
                            ToolTipLabelFormatter={TimeTooltipLabelFormatter}
                            />
            <DashboardChart Data={chartData?.firstinteractionschart} 
                            DataKey="y" 
                            XAxisProps={ {
                                          dataKey: "x", 
                                          tickFormatter: UnixTimeChartFormatter
                                        }}  
                            DataSeriesProps={{fill:"#8884d8"}}  
                            Title="First Interactions" 
                            ChartType={BarChart} 
                            SeriesType={Bar} 
                            DataFrequencyTypeLabel="Interactions"
                            ToolTipLabelFormatter={TimeTooltipLabelFormatter}
                            />
            <DashboardChart Data={chartData?.firstinteractionshourschart} 
                            DataKey="y" 
                            XAxisProps={{
                                          dataKey: "x", 
                                          type: "category"
                                          }}  
                            DataSeriesProps={{fill:"#8884d8"}}  
                            Title="First Interactions Day/Hours" 
                            ChartType={BarChart} 
                            SeriesType={Bar} 
                            DataFrequencyTypeLabel="Interactions"
                            ToolTipLabelFormatter={StringTimeLabelTooltipLabelFormatter}
                            />
            <DashboardChart Data={chartData?.customerinteractionschart} 
                            DataKey="y" 
                            XAxisProps={{
                                          dataKey: "x", 
                                          tickFormatter:UnixTimeChartFormatter,
                                          type:"number"
                                        }}  
                            DataSeriesProps={{fill:"#8884d8"}}  
                            Title="Customer Interactions" 
                            ChartType={BarChart} 
                            SeriesType={Bar} 
                            DataFrequencyTypeLabel="Interactions"
                            ToolTipLabelFormatter={TimeTooltipLabelFormatter}
                            />
            <DashboardChart Data={chartData?.customerinteractionshourschart} 
                            DataKey="y" 
                            XAxisProps={{
                                          dataKey: "x", 
                                          type: "category"
                                        }}  
                            DataSeriesProps={{fill:"#8884d8"}}  
                            Title="Customer Interactions Day/Hours" 
                            ChartType={BarChart} 
                            SeriesType={Bar} 
                            DataFrequencyTypeLabel="Interactions"
                            ToolTipLabelFormatter={StringTimeLabelTooltipLabelFormatter}
                            />
            <Card>
              <CardContent>
                <Typography variant="h6" fontWeight={"600"}>
                  Last {partner ? '1000 or 24 months' : 1000} Conversations
                </Typography>
                <Box display="flex">
                  <Box sx={{ height: 400, width: "100%" }}>
                   <DataGrid
                      components={{ Toolbar: GridToolbar }}
                      rows={rows}
                      columns={columns}
                      pageSize={50}
                      rowsPerPageOptions={[50]}
                      checkboxSelection
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                      density="compact"
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </PageLayout>

    </>
  );
};

export default Dashboard;
