import { ReactElement, useEffect, useState } from "react";

interface ComponentFunc<T> {
    (props : LoadingOutput<T>): ReactElement
}
interface LoadingFunc<T> {
    ():  Promise<ApiResponse<T>>
}


interface Props<T> {
    Component: ComponentFunc<T>,
    LoadingFunction: LoadingFunc<T>,

}
function Loading<T>(props: Props<T>): ReactElement {

    const [getLoadingState, setLoadingState] = useState(true);
    const [getErrorState, setErrorState] = useState(false);
    const [getErrorMessage, setErrorMessage] = useState("");

    const [getProps, setProps] = useState<T[]>([])
    console.log('loading props', props)
    useEffect(() => {
        console.log("running loading comp useEffect")
        setTimeout(() => {
            (async () => {
                try {
                    const newProps = await props.LoadingFunction() as ApiResponse<T>;
                    //console.log('newprops', newProps);
                    setProps(newProps.data)
                    setLoadingState(false);
                }
                catch (error: any) {
                    setErrorState(true)
                    setErrorMessage(error.message)
                }
            })();
        }, 500);

    }, [props])

    if (getErrorState)
        return <p>Error: {getErrorMessage}</p>
    if (getLoadingState)
        return <p>loading...</p>;
    if(getProps != null)
        return <props.Component data={getProps} />
    return <></>
}

export interface ApiResponse<T> {
    data: T[],
    success: boolean

}
export interface LoadingOutput<T> {
    data: T[],

}

export default Loading;


