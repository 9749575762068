import PageHeader from "../Components/PageHeading";
import PageLayout from "../Components/PageLayout"
import { useParams } from 'react-router-dom'
import Button from "@mui/material/Button";
import { useState } from "react";
import BackButton from "../Components/BackButton";
import { Box, Card, CardContent } from "@mui/material";
import { DatePicker, } from "@mui/x-date-pickers";

//import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from "moment";
import 'moment/locale/en-gb';
import axios from "axios";
import BaseUrl from "../constants/baseUrl";
import { DataGrid, GridColumns, GridToolbar } from "@mui/x-data-grid";
import { nameof } from "../utils/nameof";
import CustomQueryComponent from "../Components/CustomQuery/CustomQueryComponent";
interface ConversationSinceItem {
    partnerid: string,
    uniqueUser: string
    petName: string,
    firstAskedAt: Date,
    outcome: string,
    BodySystem: string,
    NumberOfInteractions: number
  }

const PartnerDetails = () => {
    const { id } = useParams()
    const [searchFieldValue, setSearchFieldValue] = useState<Moment|null>(moment().subtract(1, 'month'))
    const [loading, setLoading] = useState<""|"loading"|"Loaded">("")
    const [data, setData] = useState<any>(null)


    const doData = async() => {
        setLoading("loading")
        const options = {
            method: "POST",
            url: `${BaseUrl}/api/partners/conversationsSince`,
            headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
            data: {partner: id, since: searchFieldValue }

        };
        const response = await axios.request(options);
        setLoading('Loaded')
        //console.log('data', response.data.data)
        setData(response.data.data);
    }
    moment.locale('en-gb')
    const columns : GridColumns = [
        {
            field: nameof<ConversationSinceItem>("partnerid"),
            headerName: "Partner",
            width: 100,
        },
        {
            field: nameof<ConversationSinceItem>("uniqueUser"),
            headerName: "Unique User",
            width: 150,
        },
        {
            field: nameof<ConversationSinceItem>("petName"),
            headerName: "Pet Name",
            width: 200,
        },
        {
            field: nameof<ConversationSinceItem>("firstAskedAt"),
            headerName: "First Asked At",
            width: 150,
        },
        {
            field: nameof<ConversationSinceItem>("outcome"),
            headerName: "Outcome",
            width: 200,
        },
        {
            field: nameof<ConversationSinceItem>("BodySystem"),
            headerName: "Body System(s)",
            width: 250,
        },
        {
            field: nameof<ConversationSinceItem>("NumberOfInteractions"),
            headerName: "Number Of Interactions",
            width: 100,
        },

    ]
    return (
        <PageLayout>
            <PageHeader>PARTNERS</PageHeader>
            <BackButton url='Partners' />
            
            <h2>{id}</h2>
          
            {/* {<Loading Component={PartnerInfoDetails} LoadingFunction={loadData} /> } */}
            <h2>Partner Conversation Data</h2>
            Since: 
             <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                        <DatePicker label="Conversations Since"
                          value={searchFieldValue}
                          onChange={(newValue) => setSearchFieldValue(newValue)}
                        /> 
                        <Button onClick={doData}>Get Data</Button>
                   
                    </LocalizationProvider>
            {loading === "loading" && "Loading"}
            {loading === "Loaded" &&  
            <Card>
            <CardContent>
                <Box display="flex">
                    <Box sx={{ width: "100%" }}>
                        <DataGrid
                        components={{ Toolbar: GridToolbar }}
                        rows={data}
                        columns={columns}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                        checkboxSelection
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        density="compact"
                        autoHeight={true}
                        getRowId={(row) => {return row.partner + row.uniqueUser + row.firstAskedAt}}
                    />
                    </Box>
                    </Box></CardContent></Card>
                }
            <CustomQueryComponent queryType="forPartner" queryParamValue={id} />
        </PageLayout>
    );

}


const PartnerInfoDetails = (props: any) => {
    const [isEditing, setIsEditing] = useState(false);
    return (
        isEditing ? 
        <PartnerDetailsEdit {...props} toggleEditMode={() => setIsEditing(false)} />
        :
        <PartnerDetailsView {...props} toggleEditMode={() => setIsEditing(true)} />
    )

}

const PartnerDetailsView = (props : any) => {
    return <>
         <Button onClick={props.toggleEditMode}>Edit</Button>
        <p>View Mode
            {props.partnerName} </p>
    </>
}

const PartnerDetailsEdit = (props:any) => {
    return <>
        <Button onClick={props.toggleEditMode}>Cancel Edit</Button>
        <p>Edit Mode {props.partnerName}</p>
        <Button onClick={() => {alert('saving'); props.toggleEditMode()}}>Save</Button>
    </>
}


export default PartnerDetails;