import { useEffect, useState } from "react";
import BaseUrl from "../../constants/baseUrl";
import axios from "axios";
import { DataGrid, GridColumns, GridEnrichedColDef, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";
import { Box, Card, CardContent, FormControl, MenuItem, Select, Typography } from "@mui/material";

function IsDate(val:string) : Boolean
{
    if (typeof(val) !== "string") return false;
    const parsedDate = Date.parse(val);
    return !isNaN(parsedDate) && (val.includes("/") || val.includes('-'));
}

const CustomQueryComponent = (props : {queryType: string, queryParamValue:(number|string|undefined)}) => {
    const queryType:string = props.queryType
    const queryParamValue:(number|string|undefined) = props.queryParamValue
    const [loaded, setLoaded] = useState(false)
    const [data, setData] = useState<any>(null)
    const [query, setQuery] = useState<string>("")
    const [queryList, setQueryList] = useState<string[]|null>(null)


    useEffect(() => {
        async function fetchQueries() {
            console.log("getting data");
            var options = {
                method: "GET",
                url: `${BaseUrl}/api/queries`,
                params: { type: queryType },
                headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
            };
            const response = await axios.request(options);

            setQueryList(response.data.data);
        }
        fetchQueries();
    }, [])

    useEffect(() => {
        async function getData() {
            console.log("getting data");
            var options = {
                method: "POST",
                url: `${BaseUrl}/api/queries?`,
                params: { queryToRun: query, type: queryType , queryParamValue: queryParamValue},
                headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
            };
            const response = await axios.request(options);

            setData(response.data);
            setLoaded(true);
        }
        if (!loaded && query !== "") {
            getData();
        }
    }, [loaded, data, query]);

    let dataGridRender = <></>
    if (data?.data?.length > 0) {

        const columns: GridColumns = Object.keys(data.data[0]).filter(x => x !== "id").map(field => {
            let column:GridEnrichedColDef = {
                field: field,
                headerName: field,
                width: 150,

            };
            if (field.endsWith('as string')) {
                column.headerName = field.replace('as string', '')
            }
            else {
                if (field.toLocaleLowerCase() === "url") {
                    column = {
                        ...column,
                        width: 250,
                        renderCell: (params: GridRenderCellParams) => {
                            return <a href={`${params.value}`}>{params.value}</a>
                        }
                    }
                }
                if (IsDate(data.data[0][field])) {
                    column = { 
                        ...column,
                        valueFormatter: ({value}) => {
                            const d = new Date(value);
                            return d.toLocaleString('en-gb')
                        }
                    }
                }
                if (field.toLocaleLowerCase() === "email") {
                    column = {
                        ...column,
                        width: 250,
                        renderCell: (params: GridRenderCellParams) => {
                            return <a href={`/users/${params.value}`}>{params.value}</a>
                        }
                    }
                }
            }
            return column;
        })
        console.log('cols', columns)
        dataGridRender = <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={data.data}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            density="compact"
        //getRowId={(row) => row.PartnerCode}
        />
    }

return (<><h2>Pre-Prepared Data Requests</h2>
<Box display="flex" alignItems={"center"}>
    <Typography>Choose Query:</Typography>
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={query}
            label="Query"
            onChange={(e) => {
                setQuery(e.target.value);
                setLoaded(false);
            }}
        >
            <MenuItem key={-1} value={''}> </MenuItem>
            {queryList?.map((ele, i) => (
                <MenuItem key={i} value={ele}>
                    {ele}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
</Box>
<Card>
    <CardContent>
        <Box display="flex">
            <Box sx={{ height: 400, width: "100%" }}>
                {dataGridRender}
            </Box>
        </Box>
    </CardContent>
</Card></>)

}

export default CustomQueryComponent;