
import { GoogleLogin } from '@react-oauth/google';
import urls from './constants/urls';
import { useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import { Box, Alert, Typography } from "@mui/material";
//import { hasGrantedAllScopesGoogle } from '@react-oauth/google';





function LoginScreen() {
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  return (
    <div className="App" style={{ height: "100%", backgroundImage: "url('Group-2045.png')", backgroundSize: "cover", backgroundPosition: "50%", backgroundColor: "#fff", backgroundRepeat: "no-repeat" }}>

      <Box display="flex" justifyContent={"center"} flexWrap='wrap-reverse' height={"100%"}>
        <Box display="flex" alignItems={"center"}  justifyContent={"center"}>
          <Box>
            <Box display="flex" justifyContent={"center"} alignItems={"center"} flexWrap="wrap">
              <Typography variant="h2">Welcome to  &nbsp;</Typography><img src="/Logo.png" width="250px" height="60px" alt="VidiVet" />
            </Box>

            <Typography variant="h4" align="center" sx={{marginTop: "24px"}}> Administration Portal</Typography>
            <Box   display="flex" justifyContent="center"  alignItems="center" sx={{marginTop: "24px"}}>
              {alertMessage !== "" && <Alert severity="error">{alertMessage}</Alert>}
              <GoogleLogin 
                onSuccess={credentialResponse => {
                  console.log(credentialResponse);
                  const token : {hd: string}  = jwt_decode(credentialResponse.credential!);

                  // const hasAccess = hasGrantedAllScopesGoogle(
                  //   credentialResponse,
                  //   'https://www.googleapis.com/auth/drive',
                  // );
                  // console.log("Has Accces", hasAccess)

                  if (token.hd !== "vidivet.com") {
                    setAlertMessage("You must login with a VidiVet Google Account")
                  }
                  else {
                    localStorage.setItem("token", credentialResponse.credential!);
                    navigate(urls.Dashboard)
                  }
                  console.log(token);

                }}
                onError={() => {
                  setAlertMessage("Login Failed");
                }}
              /> 

             

            </Box>
          </Box>
        </Box>
        <Box display="flex" sx={{ margin: "30px"}}>
        <img src="/gp-11-1.png"alt="" />
        </Box>


      </Box>






    </div>
  );
}

export default LoginScreen;
