import { DashboardSharp } from '@mui/icons-material';
import Header from './Header'

import { AppBar, BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import urls from '../constants/urls';

type Props= {
    children: React.ReactNode
}

function PageLayout(props : Readonly<Props>) {
    const navigate = useNavigate();
    return (
        <>
            <Header></Header>
            <Box p={"12px"}>
            {props.children}
            </Box>
            <AppBar position="fixed" color="primary" style={{top: "auto", bottom: 0}}>
                <BottomNavigation showLabels>
                    <BottomNavigationAction label="Partner Stats" icon={<DashboardSharp />} onClick={() => navigate(urls.Dashboard)}/>
                    <BottomNavigationAction label="Metrics" icon={<DashboardSharp />} onClick={() => navigate(urls.Metrics)} />
                    <BottomNavigationAction label="Users" icon={<DashboardSharp />} onClick={() => navigate(urls.Users)} />
                    <BottomNavigationAction label="Vets" icon={<DashboardSharp />} onClick={() => navigate(urls.Vets)} />
                    <BottomNavigationAction label="Partners" icon={<DashboardSharp />} onClick={() => navigate(urls.Partners)} />
                </BottomNavigation>
            </AppBar>
        </>
    );

}


export default PageLayout