import { createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
const vidivetColor = {
  50: "#fbe9e7",
  100: "#ffccbc",
  200: "#ffab91",
  300: "#ff8965",
  400: "#ff6f43",
  500: "#ff5622",
  600: "#f4501e",
  700: "#e64919",
  800: "#d84215",
  900: "#bf350c",

}

const theme = createTheme({
  palette: {
    primary: {
      main: vidivetColor[500],
    },
    secondary: {
      main: blue[500],
    },
  },
  typography: {
    "fontFamily": `"Ubuntu", "Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   }
});

export default theme;
