import { Typography, Button, Card, CardContent, Box } from '@mui/material';
import { DataGrid, GridColumns, GridToolbar, GridValueGetterParams } from "@mui/x-data-grid";
import { useState } from 'react';
import BaseUrl from '../constants/baseUrl';
import axios from "axios";
import FullScreenDialog from './FullScreenDialog';

type Props= {
  partner: string
}
 type PartnerReportPreviewData = { 
  MonthlyData: any[],
  CustomerInteractionsReport : readonly any[]
}
const PreviewPartnerReport = (props : Props) => {
    const [partnerReportPreviewData, setpartnerReportPreviewData] = useState<PartnerReportPreviewData | null>(null);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    
    async function getReportData () {
        var options = {
            method: "GET",
            url: `${BaseUrl}/api/statsReport`,
            params: { partner: props.partner },
            headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          };
          setLoading(true)
          const response = await axios.request(options);
          setpartnerReportPreviewData(response.data)
          setLoading(false);
          setOpen(true)
    }


    const generateButton = <Button onClick={getReportData}> Preview Monthly Report</Button> 

    const loadingMessage = loading ? <p>Loading</p>: null;
    
    const monthlyDataColumns = [
        {     
            field: "month",
            headerName: "Month",
            width: 200,
          },    
          {     
            field: "signups",
            headerName: "Signups",
            width: 120,
          },    
          {     
            field: "cancellations",
            headerName: "Cancellations",
            width: 120,
          },
          {     
            field: "questions",
            headerName: "Questions",
            width: 120,
          },
          {     
            field: "interactions",
            headerName: "Interactions",
            width: 120,
          },
          {
            field: "interactions2",
              headerName: "Interactions per Question",
              valueGetter: (params : GridValueGetterParams) => params.row.questions > 0 ? (params.row.interactions / params.row.questions).toFixed(2) : 0,
              width:200
          },
          {     
            field: "peakHours",
            headerName: "Peak Hours",
            width: 200,
          },
          {     
            field: "peakDays",
            headerName: "Peak Days",
            width: 200,
          },
          
          

    ]
    
    const monthlyDataReport =  partnerReportPreviewData &&            <Card>
    <CardContent>
      <Typography variant="h6" fontWeight={"600"}>
        Monthly Report for {props.partner}
      </Typography>
      <Box display="flex">
        <Box sx={{ height: 600, width: "100%" }}>
          <DataGrid
          components={{ Toolbar: GridToolbar }}
            rows={partnerReportPreviewData.MonthlyData}
            columns={monthlyDataColumns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            checkboxSelection
            disableSelectionOnClick
            //experimentalFeatures={{ newEditingApi: true }}
            getRowId={row => row.month}
            density="compact"
          />
        </Box>
      </Box>
    </CardContent>
  </Card>




const asksReportColumns : GridColumns = [
  {
    field: "partnerId",
    headerName: "Partner",
    width: 100,
  },
  {     
      field: "firstName",
      headerName: "Firstname",
      width: 150,
    },    
    {     
      field: "lastName",
      headerName: "Lastname",
      width: 180,
    },    
    {     
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {     
      field: "petName",
      headerName: "Pet Name",
      width: 150,
    },
    {     
      field: "firstAskedAt",
      headerName: "Question First Asked",
      width: 180,
      valueFormatter: ({value}) => {
        const d = new Date(value);
        return d.toLocaleString('en-gb')
      }
    },
    {     
      field: "outcome",
      headerName: "Recorded Outcome",
      width: 280,
    },
    {     
      field: "bodySystem",
      headerName: "Body System of Symptom",
      width: 280,
    },
    {     
      field: "numberOfInteractions",
      headerName: "Number of Interactions in Question",
      width: 250,
    },
    // {     
    //   field: "index",
    //   headerName: "index ",
    //   width: 200,
    // },
    // {     
    //   field: "id",
    //   headerName: "id ",
    //   width: 200,
    // },
    
    

]

const asksReport =  partnerReportPreviewData &&            <Card>
<CardContent>
<Typography variant="h6" fontWeight={"600"}>
  Last Month's Asks Report for {props.partner}
</Typography>
<Box display="flex">
  <Box sx={{ height: 600, width: "100%" }}>
    <DataGrid
    components={{ Toolbar: GridToolbar }}
      rows={partnerReportPreviewData.CustomerInteractionsReport.map((row, index) => {return {...row, index: index, id: row.firstAskedAt + ' ' + row.email}})}
      columns={asksReportColumns}
      pageSize={100}
      rowsPerPageOptions={[100]}
      checkboxSelection
      disableSelectionOnClick
      //experimentalFeatures={{ newEditingApi: true }}
      getRowId={row => row.firstAskedAt + ' ' + row.email + row.index }
      density="compact"
    />
  </Box>
</Box>
</CardContent>
</Card>



    
    if (!props.partner) {
        return null;
    }

    return (<>
            {generateButton }

            {loadingMessage}
            <FullScreenDialog title="Monthly Report" setOpen={setOpen} open={open}>
                {monthlyDataReport}
                {asksReport}
            </FullScreenDialog>
        </>);

}

export default PreviewPartnerReport;