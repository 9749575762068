import { useEffect, useState } from "react";
import PageHeader from "../Components/PageHeading";
import PageLayout from "../Components/PageLayout"
import { useParams } from 'react-router-dom'
import BaseUrl from "../constants/baseUrl";
import axios from "axios";
import { DataGrid, GridColumns, GridEnrichedColDef, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";
import { Box, Card, CardContent, FormControl, MenuItem, Select, Typography } from "@mui/material";
import CustomQueryComponent from "../Components/CustomQuery/CustomQueryComponent";

const VetsHome = () => {
    return (
        <PageLayout>
            <PageHeader>VETS</PageHeader>
            <CustomQueryComponent queryType="vet" queryParamValue=""/>
        </PageLayout>
    );

}




export default VetsHome;