import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import LoginScreen from './LoginScreen';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import urls from './constants/urls';
import "@fontsource/ubuntu"
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import { ThemeProvider } from '@mui/material';
import Dashboard from './Views/Dashboard';
import { GoogleClientId } from './constants/config';
import UsersHome from './Views/UsersHome';
import UserDetails from './Views/UserDetails';
import Metrics from './Views/Metrics';
import PartnersHome from './Views/Partners';
import VetsHome from './Views/Vets';
import PartnerDetails from './Views/PartnerDetails';

const router = createBrowserRouter([
  {
    path: urls.Home,
    element: <LoginScreen />
  },
  {
    path: urls.Dashboard,
    element: <Dashboard />
  },
  {
    path: urls.Users,
    element: <UsersHome />
  },
  {
    path: urls.UserDetails,
    element: <UserDetails />
  },
  {
    path: urls.Metrics,
    element: <Metrics />
  },
  {
    path: urls.Vets,
    element: <VetsHome />
  }, 
  {
    path: urls.PartnerDetails,
    element: <PartnerDetails />
  },
  {
    path: urls.Partners,
    element: <PartnersHome />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
    <GoogleOAuthProvider clientId={GoogleClientId}>
      <div className="app">
        <RouterProvider router={router} />
      </div>
    </GoogleOAuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
