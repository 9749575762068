import { Typography } from "@mui/material"

type childProps = { children :string | JSX.Element | JSX.Element[]}
const PageHeading = ({children}: childProps ) => {

    return (
        <Typography variant="h5" fontWeight={"600"} mb="24px">
        {children}{" "}
      </Typography>
    )
}
export default PageHeading