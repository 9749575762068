import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import urls from "../constants/urls"

interface Props {
    url : keyof typeof urls
}

const BackButton = (props : Props) =>{
    const navigate = useNavigate()
    return <Button onClick={() => navigate(urls[props.url])} > &lt; Back</Button>
}

export default BackButton