const urls = {
    Home: "/",
    Dashboard : "/dashboard",
    Users: "/users",
    UserDetails: "/users/:id",
    Metrics: "/metrics",
    Partners: "/partners",
    PartnerDetails: "/partners/details/:id",
    Vets: "/vets"

}


export default urls;
