import { DashboardSharp } from "@mui/icons-material"
import { Box, Typography} from "@mui/material";
function Header() {
    return (<>
        <Box display="flex" justifyContent={"space-between"} alignItems={"center"} sx={{backgroundColor: "#fce1ca", padding:"12px 12px 24px 12px"}}>
            <Box display="flex"><img src="/Logo.png" alt="VidiVet" width={"200px"} /></Box>
            <Box display="flex" alignItems={"center"}><DashboardSharp /> <Typography variant="h5">Administrative Portal</Typography> </Box>
        </Box>
    </>)
}


export default Header;