import {
  Box,
  Typography,
  Card,
  CardContent,
  Tooltip as MUITooltip
} from "@mui/material";
import { CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

const DashboardChart = (props:any) => {

  return (
<Card sx={{padding:"12px"}} raised>
              <CardContent>
                <Typography variant="h6" fontWeight={"600"}>
                  {props.Title}
                </Typography>
                <Box display="flex">
                  <ResponsiveContainer width="99%" height={600}>
                    <props.ChartType data={props.Data}>
                      <props.SeriesType type="monotone" dataKey={props.DataKey}{...props.DataSeriesProps} />
                      <CartesianGrid stroke="#ccc" />
                      <XAxis
                        domain={["dataMin", "dataMax + 1000000"]}
                        name="Time"
                        type="number"
                        tickCount={10}
                        {...props.XAxisProps}
                      />
                      <YAxis />
                      <Tooltip
                        formatter={(value, name, p) => [value, props.DataFrequencyTypeLabel]}
                        labelFormatter={props.ToolTipLabelFormatter}
                      />
                    
                    </props.ChartType>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
  );
}

export default DashboardChart