import PageHeader from "../Components/PageHeading";
import PageLayout from "../Components/PageLayout"
import { generatePath, useNavigate } from 'react-router-dom'
import BaseUrl from "../constants/baseUrl";
import axios from "axios";
import { DataGrid, GridColumns, GridToolbar } from "@mui/x-data-grid";
import { nameof } from "../utils/nameof";
import {
    Box,
    Button,
    Card,
    CardContent
} from "@mui/material";
import Loading, { ApiResponse, LoadingOutput } from "../Components/Loading";
import urls from "../constants/urls";



interface PartnerListItem {
    PartnerCode: string,
    PartnerName: string,
    PartnerSize: undefined | number,
    IsPractice: null | boolean

}
async function getAllPartners () : Promise<ApiResponse<PartnerListItem>>  {
    console.log("Loading Data for All Partners");
    const options = {
        method: "GET",
        url: `${BaseUrl}/api/partners/list`,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    const resp =  await axios.request(options);
    return resp.data;

}


const PartnersHome = () => {


    return (
        <PageLayout>
            <PageHeader>PARTNERS</PageHeader>
            <Card>
                <CardContent>
                    <Box display="flex">
                        <Box sx={{ height: 400, width: "100%" }}>
                            <Loading Component={AllPartnersList} LoadingFunction={getAllPartners} />
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </PageLayout>
    );

}

const AllPartnersList = (props: LoadingOutput<PartnerListItem>) => {
    const navigate = useNavigate();
    const columns: GridColumns = [

        {
            field: nameof<PartnerListItem>("PartnerCode"),
            headerName: "Partner Code",
            width: 150,

        },
        {
            field: nameof<PartnerListItem>("PartnerName"),
            headerName: "Partner Name",
            width: 350
        },
        {
            field: "md",
            headerName: "More Details",
            renderCell: (params) => {
                return (<Button onClick={()=> 
                      navigate(generatePath(urls.PartnerDetails, {id: params.row.PartnerCode}))
            }>More Details</Button>)
            }

        }
    ]

    return <DataGrid
        components={{ Toolbar: GridToolbar }}
        rows={props.data}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        density="compact"
        getRowId={(row) => row.PartnerCode}
    />
}


export default PartnersHome;